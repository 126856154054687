<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>{{ t('Customer Funnel') }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0" />
    </b-card-header>
    <b-card-body>
      <b-form>
        <b-row>
          <b-col cols="3">
            <b-form-group>
              <v-select
                v-model="form.user"
                class="p-0 border-0"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="username"
                :reduce="op => op.companyuserid"
                placeholder="Select User"
                :options="userData"
                @input="filterData($event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <v-select
                v-model="form.period"
                class="p-0 border-0"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :reduce="op => op.value"
                :placeholder="t('Period')"
                :options="periodOptions[$i18n.locale]"
                @input="onChangePeriod($event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="form.start_date"
                  :placeholder="t('Select Start Date')"
                  class="form-control"
                  :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
                  :config="{ dateFormat: 'Y-m-d'}"
                />
                <!-- <b-form-datepicker
                  v-model="form.start_date"
                  local="en"
                  :placeholder="t('Select Start Date')"
                  class="mb-1 form-control"
                  selected-variant="primary"
                  :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
                  :config="{ dateFormat: 'Y-m-d'}"
                /> -->
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="form.end_date"
                  class="form-control"
                  :placeholder="t('Select End Date')"
                  :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
                  :config="{ dateFormat: 'Y-m-d'}"
                />
                <!-- <b-form-datepicker
                  v-model="form.end_date"
                  local="en"
                  :placeholder="t('Select End Date')"
                  class="mb-1 form-control"
                  selected-variant="primary"
                  :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
                  :config="{ dateFormat: 'Y-m-d'}"
                /> -->
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-row>
        <b-col
          xl="6"
          md="6"
          sm="12"
        >
          <b-table-simple
            hover
            caption-top
            responsive
            class="rounded-bottom mb-0 table table-bordered"
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th>Status</b-th>
                <b-th>Count</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(item, index) in FunnelTableData"
                :key="index"
              >
                <b-td class="text-left">
                  {{ item.status_name }}
                </b-td>
                <b-td
                  class="text-info font-weight-bold"
                  @click="openModal(item)"
                >
                  {{ item.count_value }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <b-col
          xl="6"
          md="6"
          sm="12"
        >
          <div id="chart">
            <apexchart
              type="bar"
              height="350"
              :options="chartOptions"
              :series="series"
            >
            </apexchart>
          </div>
          <funnel-report-modal
            :funnel-report-data="funnelReportData"
            :funnel-modal-data="funnelModalData"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol,
  BInputGroup, BFormGroup, BForm,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import { useUtils } from '@core/libs/i18n'
import store from '../../../store'
import constants from '@/constants'
import {
  periodOptions, getDateByPeriod, hideLoader, showLoader,
} from '@/utils/common'
import FunnelReportModal from './FunnelReportModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    flatPickr,
    BInputGroup,
    BFormGroup,
    BForm,
    // BFormDatepicker,
    apexchart: VueApexCharts,
    vSelect,
    FunnelReportModal,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      form: {
        period: '',
        start_date: '',
        end_date: '',
        user: JSON.parse(localStorage.getItem('userData')).companyuserid,
      },
      userData: [],
      periodOptions,
      isDateDisable: true,
      seriesData: [],
      xaxisCategories: [],
      FunnelTableData: [],
      series: [],
      chartOptions: {},
      funnelModalData: [],
      funnelReportData: [],
    }
  },
  mounted() {
    this.getUserList()
    this.form.start_date = moment().startOf('year').format('YYYY-MM-DD')
    this.form.end_date = moment().endOf('year').format('YYYY-MM-DD')
    this.form.period = '5'
    this.filterData()
  },
  methods: {
    getUserList() {
      showLoader()
      store.dispatch('DashboardApi/GetPipeLineReport', this.form).then(async response => {
        if (response.data.code === constants.SUCCESS) {
          this.userData = response.data.data.userList
          hideLoader()
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something went wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    openModal(event) {
      this.funnelReportData = []
      showLoader()
      this.$bvModal.show('funnelReportModal')
      store.dispatch('DashboardApi/GetFunnelLeadData',
        {
          lead_status: event.lead_status,
          user: this.form.user,
          period: this.form.period,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
        }).then(async response => {
        if (response.data.code === constants.SUCCESS) {
          this.funnelReportData = response.data.data
          hideLoader()
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something went wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    onChangePeriod(val) {
      const dates = getDateByPeriod(val)
      this.form.start_date = dates['0']
      this.form.end_date = dates['1']
      this.isDateDisable = dates['2']
      this.filterData()
    },
    filterData() {
      this.series = []
      this.chartOptions = {}
      this.seriesData = []
      this.xaxisCategories = []
      showLoader()
      store.dispatch('DashboardApi/GetFunnelReport', this.form).then(async response => {
        if (response.data.code === constants.SUCCESS) {
          response.data.data.forEach(element => {
            this.xaxisCategories.push(element.status_name)
            this.seriesData.push(element.count_value)
          })
          this.FunnelTableData = response.data.data
          this.series = [
            {
              name: 'Funnel Series',
              data: this.seriesData,
            },
          ]
          this.chartOptions = {
            chart: {
              type: 'bar',
              height: 350,
              dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 0,
                color: '#fff',
                opacity: 0,
              },
              chart: {
                background: '#fff',
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 0,
                horizontal: true,
                barHeight: '80%',
                isFunnel: true,
              },
            },
            dataLabels: {
              enabled: true,
              formatter(val) {
                return val
              },
              dropShadow: {
                enabled: false,
              },
            },
            title: {
              text: 'Customer Funnel',
              align: 'middle',
            },
            xaxis: {
              categories: this.xaxisCategories,
            },
            legend: {
              show: false,
            },
          }
          hideLoader()
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something went wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
